import React from "react"

export const TitleUnderlineLeftOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="218" height="8" viewBox="0 0 218 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M40.5296 8C36.0225 7.25735 3.02083 8.08646 0.622065 7.86265C-1.3363 7.56254 1.79334 2.27751 3.61115 1.49925C4.24832 1.21949 4.98856 1.17881 6.68456 1.30597C9.3738 1.54505 44.1465 1.07198 44.1465 1.07198C44.6056 1.23475 45.983 0.685396 46.236 0.883775C47.0325 1.34666 103.394 0.715913 108.201 0.69048C111.115 0.67522 108.81 0.99059 112.239 0.863424C119.548 0.609091 135.477 0.853252 141.015 0.563314C145.447 0.324241 146.403 0.314075 150.657 0.507368C155.005 0.680314 178.121 0.431066 180.763 0.0597408C181.41 -0.0419921 181.907 -0.00638251 181.907 0.11061C181.907 0.136044 198.201 0.222513 198.276 0.314073C198.398 0.431066 198.895 0.487015 199.354 0.405629C200.207 0.283549 217.551 -0.15899 217.823 0.181815C218.675 1.15845 216.286 5.31932 214.271 6.33157C212.21 7.41502 200.891 6.19932 191.792 6.85041L149.43 6.91654L140.35 7.32347C139.31 6.97249 135.309 7.85756 132.891 7.22682C131.87 6.97757 124.655 6.94196 123.062 7.20647C121.675 7.40994 84.7662 6.89618 80.9806 7.60323C74.2341 7.01318 46.8732 7.85757 40.5296 8Z" fill="#FFA700" />
    </svg>
  </span>
)

export const TitleUnderlineLeftTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="242" height="8" viewBox="0 0 242 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M44.9916 8C39.9883 7.25735 3.3534 8.08646 0.690549 7.86265C-1.48342 7.56254 1.99077 2.27751 4.00871 1.49925C4.71602 1.21949 5.53776 1.17881 7.42048 1.30597C10.4058 1.54505 49.0066 1.07198 49.0066 1.07198C49.5163 1.23475 51.0454 0.685396 51.3262 0.883775C52.2104 1.34666 114.777 0.715913 120.113 0.69048C123.348 0.67522 120.789 0.99059 124.596 0.863424C132.709 0.609091 150.392 0.853252 156.54 0.563314C161.46 0.324241 162.521 0.314075 167.243 0.507368C172.07 0.680314 197.731 0.431066 200.664 0.0597408C201.382 -0.0419921 201.933 -0.00638251 201.933 0.11061C201.933 0.136044 220.022 0.222513 220.105 0.314073C220.24 0.431066 220.791 0.487015 221.301 0.405629C222.248 0.283549 241.501 -0.15899 241.803 0.181815C242.75 1.15845 240.097 5.31932 237.861 6.33157C235.572 7.41502 223.007 6.19932 212.907 6.85041L165.881 6.91654L155.801 7.32347C154.647 6.97249 150.205 7.85756 147.522 7.22682C146.388 6.97757 138.378 6.94196 136.61 7.20647C135.071 7.40994 94.0982 6.89618 89.8959 7.60323C82.4067 7.01318 52.0336 7.85757 44.9916 8Z" fill="#FFA700" />
    </svg>
  </span>
)

