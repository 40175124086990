/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import UniCienHuma from "@components/sharedComponents/customersLogos/logos/UniCienHuma.svg"
import Cepeban from "@components/sharedComponents/customersLogos/logos/Cepeban.svg"
import E4CC from "@components/sharedComponents/customersLogos/logos/E4CC.svg"
import DMCPeru from "@components/sharedComponents/customersLogos/logos/DMCPeru.svg"
import Gt from "@components/sharedComponents/customersLogos/logos/Gt.svg"
import Istra from "@components/sharedComponents/customersLogos/logos/Istra.svg"

//imagen articulos blog
import article1 from "@components/sharedComponents/articleBlog/images/chatbot-colegios.jpg"
import article2 from "@components/sharedComponents/articleBlog/images/marketing-digital-colegios.jpg"
import article3 from "@components/sharedComponents/articleBlog/images/mejorar-procesos-inscripcion-universidad.jpg"
import article4 from "@components/sharedComponents/articleBlog/images/mejorar-matriculas-universidad.jpg"
import article5 from "@components/sharedComponents/articleBlog/images/herramientas-de-software-educativo.jpg"

// logos testimonios
import CepebanTes from "@components/sharedComponents/testimonies/images/logos/cepeban.svg"
import GualdaTes from "@components/sharedComponents/testimonies/images/logos/gualda.svg"

//foto testimonio desktop
import cepebanDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/cepeban.png"
import gualdaDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/gualda.png"

//foto testimonio desktop
import cepebanMobile from "@components/sharedComponents/testimonies/images/fotosMobile/cepeban.png"
import gualdaMobile from "@components/sharedComponents/testimonies/images/fotosMobile/gualda.png"


//image acordeon left
import ImageAccordionLeftOne from "@components/pageIndustrias/pageEducacion/images/imageAccordion/imageOne.webp"
import ImageAccordionLeftTwo from "@components/pageIndustrias/pageEducacion/images/imageAccordion/imageTwo.webp"
import ImageAccordionLeftThree from "@components/pageIndustrias/pageEducacion/images/imageAccordion/imageThree.webp"
import ImageAccordionLeftFour from "@components/pageIndustrias/pageEducacion/images/imageAccordion/imageFour.webp"

//iconos poteciamos operaciones
import imageConversionsOne from "@components/pageIndustrias/pageEducacion/images/imageConvertions/imageOne.webp"
import imageConversionsTwo from "@components/pageIndustrias/pageEducacion/images/imageConvertions/imageTwo.webp"
import imageConversionsThree from "@components/pageIndustrias/pageEducacion/images/imageConvertions/imageThree.webp"

import imageVideo from "@components/sharedComponents/bannerVideo/image/imageEducacion.jpg"

import TitleUnderlineStart from "@components/pageIndustrias/pageEducacion/titleUnderline/titleUnderlineStart"
import imageStartContactCenter from "@components/pageIndustrias/pageEducacion/images/imageStart.webp"


const titleStart = (
    <p>
        Mejora la adquisición de nuevos alumnos con tecnología omnicanal <TitleUnderlineStart underline="impulsada por IA" />
    </p>
)

let data = {
    start: {
        title: titleStart,
        textbody: (
            <p>
                Desde la inscripción de estudiantes hasta la gestión de consultas, transforma cada punto de contacto en una experiencia educativa positiva con autoservicio basado en Inteligencia Artificial, workflows automatizados e integraciones a todo nivel.
                <br /><br />
                Nuestras soluciones están diseñadas para universidades, colegios e instituciones académicas que buscan no solo adaptarse, sino liderar, brindando una atención eficiente y personalizada a sus estudiantes.
            </p>
        ),
        image: imageStartContactCenter,
        textbutton: "Habla con un experto",
        link: "/hablemos/",
    },
    customersDesktop: [
        UniCienHuma,
        Cepeban,
        E4CC,
        DMCPeru,
        Gt,
        Istra
    ],
    customersTablet: {
        arrayOne: [UniCienHuma, Cepeban, E4CC, DMCPeru, Gt, Istra],
    },
    accordionRight: [
        {
            title: "Gestión de matrículas",
            description: "<p>Facilita la matrícula sin contacto con agentes mediante un sistema de autoservicio que guía a los estudiantes a través del proceso de inscripción, enviando recordatorios de plazos y confirmaciones automáticas, y monitorea el progreso en tiempo real.</p>",
            image: ImageAccordionLeftOne
        },
        {
            title: "Automatización en prospección",
            description: "<p>Diseña campañas de captación automatizadas, gestiona leads con seguimiento y mensajes automáticos, y califica leads según criterios predefinidos para priorizar aquellos con mayor potencial de conversión.</p>",
            image: ImageAccordionLeftTwo
        },
        {
            title: "Autoservicio con IA",
            description: "<p>Implementa chatbots e IVR para resolver consultas comunes 24/7 sobre programas de estudio, fechas y financiamiento, mejorando la experiencia del estudiante y reduciendo la carga del equipo de atención.</p>",
            image: ImageAccordionLeftThree,
        },
        {
            title: "Integración con herramientas internas",
            description: "<p>Integra la plataforma con LMS, SIS, CRM y ERP para automatizar tareas administrativas, centralizar comunicaciones y sincronizar datos en tiempo real, asegurando una gestión fluida y eficiente.</p>",
            image: ImageAccordionLeftFour
        },
    ],
    conversions: [
        {
            title: "Universidades",
            img: imageConversionsOne,
            text: "<p> - Permite a los estudiantes inscribirse sin contacto con agentes mediante un autoservicio, mejorando la experiencia y eficiencia del proceso. <br/> - Realiza seguimiento automático de leads y ejecuta campañas de captación, incrementando la matrícula de nuevos estudiantes. <br/> - Proporciona asistencia 24/7 mediante chatbots educativos, resolviendo consultas sobre programas y fechas importantes. </p>"
        },
        {
            title: "Colegios",
            img: imageConversionsTwo,
            text: "<p>- Automatiza la comunicación con padres sobre eventos, progresos y noticias, mejorando la participación y el compromiso. <br/> - Gestiona la inscripción y el seguimiento de actividades extracurriculares, simplificando la organización y participación. <br/> - Envía alertas tempranas automatizadas sobre el rendimiento académico y la asistencia de los estudiantes, facilitando la intervención oportuna. </p>",
        },
        {
            title: "Instituciones Académicas",
            img: imageConversionsThree,
            text: "<p>- Automatiza los procesos de certificación y emisión de diplomas, reduciendo tiempos de espera y errores administrativos.<br/> - Envía recordatorios automáticos de fechas de inscripción y vencimientos de pagos, asegurando el cumplimiento y evitando retrasos.<br/> - Recopila encuestas y feedback de manera automática, ayudando a mejorar la calidad y relevancia de los programas educativos.</p>",
        },
    ],

    articles: [
        {
            image: article1,
            title: "Chatbots para colegios: 7 casos de uso que impresionan",
            textbody:
                "Te contamos cuál es la importancia de utilizar chatbot para colegios y cuáles son los casos de uso que le puedes dar.",
            link: "https://beexcc.com/blog/chatbot-colegios/",
            card: true,
        },
        {
            image: article2,
            title: "Marketing digital para colegios: 7 pasos sencillos para crear tu plan",
            textbody:
                "Aquí te enseñamos a realizar un plan de marketing para colegios en tan solo 7 sencillos pasos. Así, tu institución tendrá éxito.",
            link: "https://beexcc.com/blog/marketing-digital-colegios/",
            card: true,
        },
        {
            image: article3,
            title: "Cómo mejorar los procesos de inscripción en una universidad",
            textbody:
                "Descubre los pasos a seguir para mejorar los procesos de inscripción que requiere tu universidad.",
            link: "https://beexcc.com/blog/mejorar-procesos-inscripcion-universidad/",
        },
        {
            image: article4,
            title: "10 estrategia para mejorar las matrículas en una universidad",
            textbody:
                "Aprende 10 estrategia para mejorar las matrículas en una universidad que te ayudarán a conseguir muchos más alumnos que tu competencia.",
            link: "https://beexcc.com/blog/mejorar-matriculas-universidad/",
        },
        {
            image: article5,
            title: "10 herramientas de software educativo que conocer en el 2024",
            textbody:
                "Conoce las 10 mejores herramientas de software educativo que ayudarán a cualquier institución a mejorar su atención y calidad académica. ",
            link: "https://beexcc.com/blog/herramientas-de-software-educativo/",
        },
    ],
    contacts: {
        title: "Estás a punto de contactar mejor con tus estudiantes",
        textbody: "Queremos ayudarte a que des el primer paso para mejorar tus interacciones. Agenda una reunión para que uno de nuestros especialistas se involucre con tus objetivos.",
        href: "/hablemos/",
        btn: "Quiero una asesoría gratuita",
    },
    footer: {
        product: "PRODUCTOS",
        productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
        productMobileOne: "Conversations",
        linkone: "/conversations/",
        productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
        productMobileTwo: "Contact Center",
        linktwo: "/contact-center/",
        productDesktopFour: "Validation: Software de validación de teléfono.",
        productMobileFour: "Validation",
        linkfour: "/validation/",

        solution: "SOLUCIONES",
        solutionOne: "Atención al cliente",
        linkfive: "/atencion-al-cliente/",
        solutionTwo: "Telemarketing",
        linksix: "/marketing-ventas/",
        solutionThree: "Contact Center",
        linkseven: "/contact-center/",
        solutionFour: "Cobranzas",
        linkeight: "/software-cobranzas/",
        solutionFive: "WhatsApp",
        linkwhatsapp: "/whatsapp-business-api/",
        linkchatbots: "/chatbots-online/",
        solutionSix: "Chatbots",
        linkinstagram: "/chatbot-instagram/",
        solutionSeven: "Instagram",
        linkwebchat: "/chat-en-vivo-web/",
        solutionEight: "WebChat",

        company: "EMPRESA",
        companyOne: "Nosotros",
        linknine: "/nosotros/",
        companyTwo: "Casos de éxito",
        linkten: "/casos-de-exito/",
        companyThree: "Blog",
        linkeleven: "https://beexcc.com/blog/",
        companyFour: "Hablemos",
        linktwelve: "/hablemos/",
        companySix: "Documentación",
        linkdocs: "https://beexcc.com/docs/",
        companyFive: "Recursos",
        linktthirteen: "/recursos/",

        blog: "BLOG",
        blogone: "¿Cómo elegir el mejor software para call center?",
        linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

        blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
        linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

        blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
        linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

        blogfour:
            "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
        linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

        blogfive: "9 características que las herramientas de telemarketing",
        linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

        masblog: "Conoce más sobre nuestro blog",
        linkmasblog: "https://beexcc.com/blog/",

        address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
        phone: "(+511) 277-4188",
        mail: "hi@beexcc.com",
        copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
        privacyPolicy: "Política de Privacidad",
        privacyPolicyHref: "/politicas-privacidad",
        cookiesPolicy: "Política de Cookies",
        cookiesPolicyHref: "/politicas-cookies",
    },
    testimonies: [
        {
            image: CepebanTes,
            title: "Con Beex y el API de WhatsApp mejoramos nuestra tasa de conversión de leads en +27%",
            textbody:
                "CEPEBAN, instituto especializado en banca y finanzas, ha aumentado a +1.5k la cantidad de leads mejor prospectados con chatbots por WhatsApp Business API.",
            name: "Alan De La Cruz",
            post: "Jefe de Sistemas de CEPEBAN",
            link: "/casos-de-exito/cepeban/",
            imgDesktop: cepebanDesktop,
            imgMobile: cepebanMobile,
            metrics: [
                {
                    metric: "+27%",
                    descripcion: "mejoró su tasa de conversión con sus leads",
                    color: "#FFB800",
                },
                {
                    metric: "+1.5k",
                    descripcion: "leads prospectados al mes por chatbots",
                    color: "#5831D8",
                },
                {
                    metric: "25%",
                    descripcion: "mejoró el tiempo de resolución de casos",
                    color: "#FFB800",
                },
            ],
        },
        {
            image: GualdaTes,
            title:
                "Con Beex hemos escalado nuestras ventas, mejorando el conversion rate en +27%",
            textbody:
                "Gualda Training, academia digital con más de 13k estudiantes en LATAM, ha automatizado sus procesos de marketing y ventas con la API de WhatsApp Business.",
            name: "Axel Gualda",
            post: "CEO & Founder de Gualda Training",
            link: "/casos-de-exito/gualda/",
            imgDesktop: gualdaDesktop,
            imgMobile: gualdaMobile,
            metrics: [
                {
                    metric: "88%",
                    descripcion: "han reducido el tiempo de espera por WhatsApp",
                    color: "#FFB800",
                },
                {
                    metric: "+27%",
                    descripcion: "mejoró su tasa de conversión",
                    color: "#5831D8",
                }
            ],
        },
    ],
    bannerVideo: {
        title: "¿Cómo automatizar la atención al cliente en el sector educación?",
        description: "<p>Explora cómo optimizar la atención al cliente en educación con la automatización. Descubre métodos para gestionar consultas y ofrecer soporte rápido y personalizado. <br /><br />        Aprende sobre herramientas y estrategias clave para mejorar la experiencia del usuario y optimizar los recursos disponibles en este sector fundamental.  </p>",
        image: imageVideo
    },

}

export default data
